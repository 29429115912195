import { t } from '../../../src/common/i18n'

const firstUpperCase = (s) => `${s[0].toUpperCase()}${s.slice(1)}`

interface FilterData {
  name: string
  key:
    | 'checkBox1'
    | 'checkBox2'
    | 'checkBox3'
    | 'checkBox4'
    | 'checkBox5'
    | 'checkBox6'
    | 'checkBox7'
    | 'checkBox8'
  label: string
  checked: boolean
}
const filterData: FilterData[] = [
  {
    name: 'turismo',
    key: 'checkBox1',
    label: firstUpperCase(t('ficha-taller.service-one')),
    checked: false,
  },
  {
    name: 'moto',
    key: 'checkBox2',
    label: firstUpperCase(t('ficha-taller.service-two')),
    checked: false,
  },

  {
    name: 'camion',
    key: 'checkBox3',
    label: firstUpperCase(t('ficha-taller.service-three')),
    checked: false,
  },

  {
    name: 'agricola',
    key: 'checkBox4',
    label: firstUpperCase(t('ficha-taller.service-four')),
    checked: false,
  },

  {
    name: 'renting',
    key: 'checkBox5',
    label: firstUpperCase(t('ficha-taller.service-five')),
    checked: false,
  },

  {
    name: 'patinete',
    key: 'checkBox6',
    label: firstUpperCase(t('ficha-taller.service-six')),
    checked: false,
  },

  {
    name: 'abierto_sabado',
    key: 'checkBox7',
    label: firstUpperCase(t('ficha-taller.abierto_sabados')),
    checked: false,
  },

  {
    name: 'abierto_mediodia',
    key: 'checkBox8',
    label: firstUpperCase(t('ficha-taller.abierto_mediodia')),
    checked: false,
  },
]
export default filterData
