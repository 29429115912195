import React from 'react'
import styles from './TalleresSearcher.module.scss'
import { FormattedMessage } from 'react-intl'
// import TalleresGeo from '../../../../common/components/cesta/talleres/TalleresGeo'
import TalleresGeo from '../../../../../src/common/components/cesta/talleres/TalleresGeo'
import { t } from '../../../../../sites/rodi/i18n'

interface Props {
  postCode: string
  setState: (postCode: string) => void
  retrieveTalleres: (postcode: string) => void
  loading: boolean
  geolocateMe: () => void
  showPostCodeLabel: boolean
  parentStyles?: {
    formStyles: any
    inputStlyes: any
    buttonStyles: any
    geoStyles: any
  }
  btnText?: string
}

export default function TalleresSearcher({
  postCode,
  setState,
  retrieveTalleres,
  loading,
  geolocateMe,
  showPostCodeLabel = false,
  parentStyles,
  btnText = 'OK',
}: Props) {
  const onHandleSubmit = (event) => {
    event.preventDefault()
    if (postCode.length === 5) {
      retrieveTalleres(postCode)
    }
  }

  const handleChange = (event) => {
    setState(event.target.value)
  }

  return (
    <div className={styles.searcher_container}>
      {showPostCodeLabel ? (
        <p className={styles.cp}>
          <FormattedMessage id="registerForm.postCode" />
        </p>
      ) : null}
      <form
        className={`${styles.post_code_form} ${
          (parentStyles && parentStyles.formStyles) || ''
        }`}
        onSubmit={onHandleSubmit}>
        <input
          className={(parentStyles && parentStyles.inputStlyes) || ''}
          value={postCode}
          onChange={handleChange}
          type="text"
          placeholder={t('talleres.input-place-holder')}
        />
        <button
          className={(parentStyles && parentStyles.buttonStyles) || ''}
          type="submit">
          {btnText.toUpperCase()}
        </button>
        <div className={parentStyles && parentStyles.geoStyles}>
          <TalleresGeo
            setState={setState}
            loading={loading}
            geolocateMe={geolocateMe}
          />
        </div>
      </form>
    </div>
  )
}
